<template>
  <div class="church-details template-2">
    <header class="page-header container mx-lg d-flex">
      <h1>{{ translations.tcChurchProfile }}</h1>
      <QuickJump @church_changed="handleChurchChange()" :i18n="translations.components"></QuickJump>
    </header>
    <!-- / Page Header -->

    <div class="page-body container mx-lg">
      <div class="details">
        <div class="d-flex row primary">
          <div class="col">
            <h4>{{ church.name }}</h4>
            <div class="info">
              <p v-if="church.location.address" class="mb-0">
                {{ church.location.address }}
              </p>
              {{ cityStateDisplay }}
              {{ church.location.zip }}
              <p>
                <br />
                {{ church.phone }}
                <span v-if="church.email">|</span>
                <a :href="'mailto:' + church.email">{{ church.email }}</a>
              </p>
              <img
                v-if="!!church.church_image && church.church_image !== ''"
                :src="church.church_image"
                class="profile_image"
              />
              <camera v-if="!church.church_image || church.church_image === ''"></camera>

              <div v-if="showUpload">
                <b-form-file
                  class="mb-1 mt-1"
                  style="text-transform: uppercase"
                  ref="fileUploadImg"
                  v-model.lazy="church_image_file"
                  name="location_image"
                  :browse-text="translations.tcBrowse"
                  :placeholder="translations.tcImageOptional"
                  :drop-placeholder="translations.tcDropFileHere"
                />
                <button @click="onUpload" variant="primary" size="sm" class="btn btn-primary">
                  {{ translations.tcUploadPhoto }}
                </button>
                <button @click="onCancel" size="sm" class="ml-1 btn btn-tertiary" variant="tertiary">
                  {{ translations.tcCancel }}
                </button>
              </div>
              <div v-if="iCanSee(secured_church_details_edit_controls.edit_church_details_button)">
                <b-link @click="showUpload = true" v-if="!showUpload">
                  <span v-if="!church.church_image">{{ translations.tcAddPhoto }}</span>
                  <span v-if="church.church_image">{{ translations.tcUpdatePhoto }}</span>
                </b-link>
                <b-link @click="onDelete" v-if="church.church_image && !showUpload" class="ml-2">
                  {{ translations.tcDeletePhoto }}
                </b-link>
              </div>
            </div>
          </div>
          <div class="col">
            <h4>{{ translations.tcChurchInfo }}</h4>
            <div class="specs">
              <p>
                <strong>{{ translations.tcSupportLevel }}:</strong>
                {{ church.support }}
              </p>
              <p>
                <strong>{{ translations.tcRank }}:</strong>
                {{ church.rank }}
              </p>
              <p>
                <strong>{{ translations.tcGideonCardDisplayServiced }}:</strong>
                <br />
                {{ church.gideonCardServiced }}
              </p>
              <p>
                <strong>{{ translations.tcOtherInformation }}:</strong>
                <br />
                {{ church.other }}
              </p>
              <p v-if="church.view">
                <strong>{{ translations.tcWebsite }}:</strong>
                <br />
                <a :href="websiteURL" target="_blank">{{ church.view }}</a>
              </p>
              <b-button :to="`/programs/cm/church-details`" class="btn btn-primary">{{
                translations.tcViewMore
              }}</b-button>
            </div>
          </div>
          <div class="col">
            <h4>{{ translations.tcPrimaryContact }}</h4>
            <div class="contact">
              <h5>
                <a href="#" @click.prevent="handleNameClick(church.contact.key)">
                  {{ church.contact.name }}
                </a>
              </h5>
              <p>
                <a :href="'mailto:' + church.contact.email">{{ church.contact.email }}</a>
                <br />
                {{ church.contact.phone }}
                <br />
                <strong>{{ translations.tcRelationship }}:&nbsp;</strong> {{ churchContactRelationship }}
              </p>
              <p v-if="church.contact.spouse.text">
                <strong>{{ translations.tcSpouse }}:&nbsp;</strong>
                <a href="#" @click.prevent="handleNameClick(church.contact.spouse.key)">{{
                  church.contact.spouse.text
                }}</a>
              </p>
              <p>
                <strong>{{ translations.tcGideonRep }}:&nbsp;</strong>
                <a href="#" @click.prevent="handleNameClick(church.contact.gideonRep.key)">{{
                  church.contact.gideonRep.text
                }}</a>
              </p>
            </div>
            <div v-if="church.hidden">
              <h2>
                <b-badge>{{ translations.tcHidden }}</b-badge>
              </h2>
            </div>
          </div>
        </div>
        <div class="row d-flex events" v-if="translations.components">
          <div class="col col-4" v-for="(item, index) in presentations_widget_edited" :key="index">
            <app-event
              :obj="item"
              :i18n="translations.components"
              @edit_requested="handlePresentationClick($event)"
            ></app-event>
          </div>
        </div>
        <div class="d-flex">
          <a
            @click.prevent="downloadReport(churchReportUrl, 'DetailedChurchReport', 'pdf')"
            class="btn btn-primary mr-4"
          >
            {{ translations.tcRunDetailedChurchReport }}
          </a>
          <a @click.prevent="downloadReport(churchReport202Url, 'ChurchReport202', 'pdf')" class="btn btn-primary mr-4">
            {{ translations.tcPrintForm202 }}
          </a>
          <router-link
            v-if="iCanSee(secured_church_details_edit_controls.add_church_presentation_button)"
            class="btn btn-primary"
            :to="{ name: `add-church-presentation` }"
          >
            {{ translations.tcAddChurchPresentation }}
          </router-link>
        </div>
      </div>
      <div class="actions">
        <div class="card-deck row">
          <div class="card card-secondary col">
            <div :style="{ height: '4px' }"></div>
            <svg-contact-information></svg-contact-information>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcContactInformationTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcContactInformationContent }}
              </p>
              <b-button to="/admin/church-ministry/church-communications" variant="primary">{{
                translations.tcViewMore
              }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col">
            <div :style="{ height: '5px' }"></div>
            <svg-church-contacts></svg-church-contacts>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcChurchContactsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcChurchContactsContent }}
              </p>
              <b-button to="/programs/cm/church-contacts" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="card card-secondary col">
            <div :style="{ height: '0' }"></div>
            <svg-church-details></svg-church-details>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcChurchDetailsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcChurchDetailsContent }}
              </p>
              <b-button to="/programs/cm/church-details" variant="primary">{{ translations.tcViewMore }}</b-button>
            </div>
          </div>
        </div>
        <div class="card-deck row">
          <div class="card card-secondary col">
            <div :style="{ height: '11px' }"></div>
            <svg-pres-service-times></svg-pres-service-times>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcPresentationsServiceTimesTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcPresentationsServiceTimesContent }}
              </p>
              <b-button to="/admin/church-ministry/service-times-presentations" variant="primary">{{
                translations.tcViewMore
              }}</b-button>
            </div>
          </div>
          <div class="card card-secondary col">
            <div :style="{ height: '0' }"></div>
            <svg-visits></svg-visits>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcVisitsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcVisitsContent }}
              </p>
              <b-button @click="handleVisitsClick()" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
          <div class="card card-secondary col">
            <div :style="{ height: '15px' }"></div>
            <svg-church-members-friends></svg-church-members-friends>
            <div class="card-body">
              <h5 class="card-title">{{ translations.tcChurchMembersFriendsTitle }}</h5>
              <p class="card-text text-left">
                {{ translations.tcChurchMembersFriendsContent }}
              </p>
              <b-button to="/admin/church-ministry/church-members-friends" variant="primary">
                {{ translations.tcViewMore }}
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- / Page Body -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import camera from '@/assets/svgs/camera.vue'
import ChurchContacts from '@/assets/svgs/church-contacts.vue'
import ChurchDetails from '@/assets/svgs/church-details.vue'
import ChurchMembersFriends from '@/assets/svgs/church-members-friends.vue'
import ContactInformation from '@/assets/svgs/contact-information.vue'
import Event from '@/components/Event.vue'
import PresServiceTimes from '@/assets/svgs/presentations-service-times.vue'
import QuickJump from '@/components/quick-jump/QuickJump.vue'
import { translationMixin } from '@/mixins/translationMixin'
import Visits from '@/assets/svgs/visits.vue'
import { reportDownloadMixin } from '@/mixins/reportDownloadMixin'

export default {
  name: 'church-profile',
  mixins: [translationMixin, reportDownloadMixin],
  data() {
    return {
      church_image_file: null,
      churchContactRelationship: '',
      presentations_widget_edited: [],
      secured_church_details_edit_controls: {
        edit_church_details_button: '987cec00-9ddf-4d84-981a-b352e51d8915',
        add_church_presentation_button: '2e18fcc4-b81d-4b54-804e-9d9e8932d810',
      },
      showUpload: false,
      translations: {},
    }
  },
  components: {
    appEvent: Event,
    camera: camera,
    QuickJump: QuickJump,
    svgContactInformation: ContactInformation,
    svgChurchContacts: ChurchContacts,
    svgChurchDetails: ChurchDetails,
    svgPresServiceTimes: PresServiceTimes,
    svgVisits: Visits,
    svgChurchMembersFriends: ChurchMembersFriends,
  },
  methods: {
    ...mapActions({
      loadAddressFormOptions: 'membership/loadAddressFormOptions',
      loadChurchDetail: 'churchMinistry/loadChurchDetail',
      loadChurchReportUrls: 'churchMinistry/loadChurchReportUrls',
      savePhoto: 'churchMinistry/savePhoto',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
      setSelectedMeetingKey: 'user/setSelectedMeetingKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
    }),
    async pageLoad() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          await this.loadAddressFormOptions(),
          await this.loadChurchDetail(),
          await this.loadChurchReportUrls(),
        ]).then(() => {
          this.editPresentation_widgets()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleChurchChange() {
      await this.pageLoad()
    },
    editPresentation_widgets() {
      this.presentations_widget_edited = this.presentations_widget.map((item) => {
        item.editLink = !!item.mtg_key || !!item.nte_key
        return item
      })
    },
    async handlePresentationClick(data) {
      if (!!data.mtg_key) {
        await this.setSelectedMeetingKey(data.mtg_key)
        this.$router.push({ path: '/programs/cm/churchProfile/view-church-presentation' })
      } else if (!!data.nte_key) {
        await this.setSelectedVisitKey(data.nte_key)
        this.setRouterBackLink(this.$route.name)
        this.$router.push('/programs/cm/churchProfile/view-church-visit')
      }
    },
    handleVisitsClick() {
      this.setRouterBackLink(this.$route.name)
      this.$router.push({ path: '/programs/cm/church-visits-card' })
    },
    async handleNameClick(key) {
      await this.setSelectedIndividualKey(key)
      this.$router.push({ name: 'non-member-profile-cm' })
    },
    onFileChanged(event) {
      this.church_image_file = event.target.files[0]
    },
    async onUpload() {
      if (this.church_image_file === null) {
        this.$swal({
          icon: 'error',
          text: this.translations.tcErrorImageRequired,
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          return
        })
        return
      } else {
        var payload = {
          iim_org_key: this.userSelectedChurchKey,
          iim_image: null,
          iim_user: this.userIndKey,
          iim_delete_flag: false,
        }
        await (payload.iim_image = this.church_image_file)
        await Promise.all([this.setLoadingStatus(true), await this.savePhoto(payload)]).then((result) => {
          this.setLoadingStatus(false)
          this.$swal({
            icon: result[1] ? 'success' : 'error',
            text: result[1] ? this.translations.tcPhotoSaved : this.translations.tcErrorDuringSave,
            confirmButtonText: this.translations.tcOk,
          }).then((result) => {
            if (result.value) {
              this.pageLoad()
              this.showUpload = false
            }
          })
        })
      }
    },
    onCancel() {
      this.church_image_file = null
      this.showUpload = false
    },
    async onDelete() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToDeletePhoto,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel || 'Cancel',
      }).then((result) => {
        if (result.value) {
          this.deleteThePhoto()
        }
      })
    },
    async deleteThePhoto() {
      var payload = {
        iim_org_key: this.userSelectedChurchKey,
        iim_user: this.userIndKey,
        iim_delete_flag: true,
      }
      await Promise.all([this.setLoadingStatus(true), await this.savePhoto(payload)]).then((result) => {
        this.setLoadingStatus(false)
        this.$swal({
          icon: result ? 'success' : 'error',
          text: result ? this.translations.tcPhotoDeleted : this.translations.tcErrorDuringDelete,
          confirmButtonText: this.translations.tcOk,
        }).then((result) => {
          if (result.value) {
            this.pageLoad()
            this.showUpload = false
          }
        })
      })
    },
    updateDynamicContent() {
      if (!this.translations.common) return false

      const relationshipGuid = this.church.contact.relationship_key ? this.church.contact.relationship_key : ''
      this.churchContactRelationship = relationshipGuid
        ? this.translations.common['individual-relationship-type'][relationshipGuid]
        : this.church.contact.relationship
      return true
    },
  },
  computed: {
    ...mapGetters({
      church: 'churchMinistry/churchDetail',
      churchReport202Url: 'churchMinistry/churchReport202Url',
      churchReportUrl: 'churchMinistry/churchReportUrl',
      iCanSee: 'user/iCanSee',
      prefCulture: 'user/userPreferredCulture',
      presentations_widget: 'churchMinistry/presentations_widget',
      userIndKey: 'user/userId',
      userSelectedChurchKey: 'user/userSelectedChurchKey',
    }),
    cityStateDisplay() {
      const { city, state } = this.church.location
      if (city && state) {
        return `${city}, ${state}`
      }
      return city || state || ''
    },
    websiteURL() {
      if (!this.church.view) {
        return ''
      }
      return this.church.view.indexOf('http://') === -1 && this.church.view.indexOf('https://') === -1
        ? `http://${this.church.view}`
        : this.church.view
    },
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      Promise.all([
        await this.getViewTranslations(),
        await this.getComponentTranslations('event', 'quick-jump'),
        await this.getComponentTranslations('common.individual-relationship-type', 'common.support-level'),
        this.pageLoad(),
      ])
        .then((results) => {
          this.stripReadableText(results[2])
          const translatedText = {
            ...results[1],
            common: { ...this.translations.common },
          }
          this.$set(this.translations, 'components', translatedText)
          const supportGuid = this.church.chu_chl_key || false
          this.church.support = this.translations.common['support-level'][supportGuid] || this.church.support
        })
        .then(() => {
          this.updateDynamicContent()
        })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
@import '@/styles/bootstrap/b-button.scss';
.badge-secondary {
  text-transform: uppercase;
  font-size: 20px;
}
.col-3.date {
  min-height: 118px;
}
.church-details {
  form {
    .quick-jump {
      min-width: 201px;
    }
  }
}
.profile_image {
  max-width: 285px;
  max-height: 185px;
  object-fit: contain;
}
.details {
  margin-bottom: 46px;
  padding: 32px 40px 40px;
  background-color: #fff;
  box-shadow: 0 9px 24px 6px rgba(197, 197, 197, 0.53);
  .d-flex {
    @include breakpoint(sm) {
      flex-wrap: wrap;
      > * {
        margin-bottom: 2rem;
        margin-right: 0 !important;
        width: 100%;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.primary {
  margin-bottom: 35px;
  > .col {
    @include breakpoint(sm) {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  h5 {
    margin: 0;
    color: #1e68fb;
    font-size: 19px;
    font-weight: 700;
    letter-spacing: 1.03px;
    line-height: 26px;
  }
  strong {
    font-weight: 800;
    text-transform: uppercase;
  }
  p {
    margin-bottom: 17px;
    font-weight: 600;
    letter-spacing: 1.03px;
    line-height: 26px;
  }
  img {
    margin-top: 17px;
  }
  a {
    color: rgba(30, 104, 251, 1);
    text-decoration: underline;
    &:hover {
      color: rgba(30, 104, 251, 0.8);
    }
  }
  .btn {
    color: #fff;
    text-decoration: none;
  }
  img {
    margin: 0;
  }
  .specs {
    p {
      margin-bottom: 10px;
      line-height: 22px;
      &:last-of-type {
        margin-bottom: 17px;
      }
    }
  }
  .contact {
    strong {
      font-weight: 700;
      text-transform: capitalize;
    }
  }
}

.events {
  margin-bottom: 50px;
  .col-4 {
    &:nth-of-type(even) {
      .event .date {
        background-image: linear-gradient(135deg, #0098c7 0%, #92cfeb 100%);
        background-image: linear-gradient(135deg, hsl(194, 100%, 39%) 0%, hsl(198, 68%, 75%) 100%);
      }
    }
    @include breakpoint(sm) {
      max-width: 100%;
      width: 100%;
      flex: 0 0 auto;
      &:last-of-type .event {
        margin-bottom: 0;
      }
    }
  }
  .event {
    margin-bottom: 0;
    border: 1px solid #e9e9e9;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    h3 {
      color: $teal-800;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
}

.actions {
  @include breakpoint(sm) {
    padding: 0 1rem;
  }
}

.card-deck {
  .card {
    svg {
      display: block;
      margin: 0 auto 13px;
    }
  }
}
.card-deck.row {
  margin-bottom: 30px;

  @include breakpoint(mobile) {
    display: block;
  }
  
  @include breakpoint(sm) {
    display: block;
  }
}
</style>
